import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { FaCheckCircle as CheckIcon } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./demo.module.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Demo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {},
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(oldState => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state.formData,
      }),
    })
      .then(() => {
        this.setState({ submitted: true })
      })
      .catch(error => alert(error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { data } = this.props
    const { submitted, submitting } = this.state

    return (
      <Layout>
        <SEO title="Demo" />
        <div styleName="outer-wrapper">
          <Container styleName="customContainerDemo">
            <div styleName="inner-wrapper">
              <h1>Request Demo</h1>
              <p styleName="required--helper">* Required Fields</p>
              <p styleName="hidden">
                <label>
                  Don’t fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </p>
              <div styleName="formCard">
                <form
                  name="demo"
                  method="post"
                  action="/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                  disabled={submitted}
                  id="demo-form"
                >
                  <Row>
                    <Col sm={12}>
                      <div className="form-group" styleName="input-parent">
                        <label>Your full name *</label>

                        <input
                          type="text"
                          required
                          id="demo-input-fullname"
                          name="name"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group" styleName="input-parent">
                        <label>Your email address *</label>

                        <input
                          type="text"
                          required
                          id="demo-input-email"
                          name="email"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group" styleName="input-parent">
                        <label>Your company name *</label>

                        <input
                          type="text"
                          required
                          name="company"
                          id="demo-input-companyname"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group" styleName="input-parent">
                        <label>Do you have a message for us?</label>

                        <textarea
                          name="message"
                          className="form-control"
                          id="demo-input-message"
                          onChange={this.handleChange}
                          rows={10}
                        />
                      </div>

                      <p styleName="more-questions">
                        Tell us more about your needs
                      </p>
                      <div>
                        {" "}
                        {/* styleName="select-section" */}
                        <div className="form-group" styleName="input-parent">
                          <label htmlFor="demo-input-position">
                            Your position
                          </label>
                          <select
                            name="position"
                            id="demo-input-position"
                            onChange={this.handleChange}
                            className="form-control"
                          >
                            <option value="" disabled selected hidden />
                            {data.allPositionJson.edges.map(edge => (
                              <option
                                key={edge.node.value}
                                value={edge.node.value}
                              >
                                {edge.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group" styleName="input-parent">
                          <label htmlFor="demo-input-size">Company size</label>
                          <select
                            name="size"
                            id="demo-input-size"
                            onChange={this.handleChange}
                            className="form-control"
                          >
                            <option value="" disabled selected hidden />
                            {data.allCompanySizeJson.edges.map(edge => (
                              <option
                                key={edge.node.value}
                                value={edge.node.value}
                              >
                                {edge.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group" styleName="input-parent">
                          <label htmlFor="demo-input-industry">Industry</label>
                          <select
                            name="industry"
                            placeholder="Choose"
                            id="demo-input-industry"
                            onChange={this.handleChange}
                            className="form-control"
                          >
                            <option value="" disabled selected hidden />
                            {data.allIndustryJson.edges.map(edge => (
                              <option
                                key={edge.node.value}
                                value={edge.node.value}
                              >
                                {edge.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group" styleName="input-parent">
                          <label htmlFor="demo-input-country">
                            Country/Countries
                          </label>
                          <input
                            type="text"
                            name="country"
                            id="demo-input-country"
                            className="form-control"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <p>
                        Which areas in your company do you need our help with?
                        (select one or more)
                      </p>
                      <div
                        className="form-group"
                        styleName="input--checkbox input-parent"
                      >
                        <label className="form-group">
                          <input
                            type="checkbox"
                            id="demo-input-checkbox-cc"
                            name="area.corporate-culture"
                            onChange={this.handleChange}
                          />{" "}
                          Corporate culture
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="area.employee-engagement"
                            onChange={this.handleChange}
                            id="demo-input-checkbox-ee"
                          />{" "}
                          Employee engagement and satisfaction
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="area.employee-productivity"
                            onChange={this.handleChange}
                            id="demo-input-checkbox-ep"
                          />{" "}
                          Optimising employee productivity
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="area.managing-absence"
                            onChange={this.handleChange}
                            id="demo-input-checkbox-ma"
                          />{" "}
                          Managing absence
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="area.psyc-wellbeing"
                            onChange={this.handleChange}
                            id="demo-input-checkbox-pw"
                          />{" "}
                          Physical and/or psychological wellbeing of employees
                        </label>
                        <label className="form-group">
                          <input
                            type="checkbox"
                            name="area.management-style"
                            id="demo-input-checkbox-ms"
                            onChange={this.handleChange}
                          />{" "}
                          Management/Leadership style
                        </label>
                      </div>
                      <div>
                        {/* styleName="select-section" */}
                        <div className="form-group" styleName="input-parent">
                          <label htmlFor="demo-how-find-us">
                            How did you find us?
                          </label>
                          <select
                            name="how-find-us"
                            id="demo-how-find-us"
                            onChange={this.handleChange}
                            className="form-control"
                          >
                            <option value="" disabled selected hidden />
                            {data.allHowFindJson.edges.map(edge => (
                              <option
                                key={edge.node.value}
                                value={edge.node.value}
                              >
                                {edge.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    {submitted && (
                      <div styleName="submittedSuccess">
                        Thank you. We’ll be in touch very shortly.
                      </div>
                    )}
                    <button
                      type="submit"
                      id="demo-form-submit-button"
                      disabled={submitted || submitting}
                      className="text-white"
                    >
                      {(submitted && "Submitted") ||
                        (submitting && "Submitting...") ||
                        "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allCountryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allPositionJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allIndustryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allHowFindJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allCompanySizeJson {
      edges {
        node {
          value
          name
        }
      }
    }
  }
`
